<template>
  <div class="gallery" v-if="!loading">
    <template v-if="!cursorHide">
        <router-link class="close" to="/">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.03 21.03"><path fill="#000" d="M1.18,21.03l-1.18-1.18,9.33-9.33L0,1.18,1.18,0l9.33,9.33L19.85,0l1.18,1.18-9.33,9.33,9.33,9.33-1.18,1.18-9.33-9.33L1.18,21.03Z"/></svg>
        </router-link>
        <router-link v-if="previousProject"  class="prev" :class="{ 'limited': cursorZone }" :to="{ name: 'project', params: { id: data.prev.uid }, hash: '#last' }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.83 24.82"><path  fill="#000" d="M12.41,24.82L0,12.41,12.41,0l1.42,1.42L2.84,12.41l10.99,10.99-1.42,1.42Z"/></svg>
        </router-link>
        <div class="prev" :class="{'limited': cursorZone}" v-if="slideIndex > 0" @click.stop="prev">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.83 24.82"><path  fill="#000" d="M12.41,24.82L0,12.41,12.41,0l1.42,1.42L2.84,12.41l10.99,10.99-1.42,1.42Z"/></svg>
        </div>
        <router-link v-if="nextProject" class="next" :class="{ 'limited': cursorZone }" :to="{ name: 'project', params: { id: data.next.uid }, hash: '#0' }" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.83 24.82"><path fill="#000" d="M1.42,24.82l-1.42-1.42,10.99-10.99L0,1.42,1.42,0l12.41,12.41L1.42,24.82Z"/></svg>
        </router-link>
        <div v-if="slideIndex < slides - 1" class="next" :class="{'limited': cursorZone}" @click.stop="next">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.83 24.82"><path fill="#000" d="M1.42,24.82l-1.42-1.42,10.99-10.99L0,1.42,1.42,0l12.41,12.41L1.42,24.82Z"/></svg>
        </div>
        <div class="down" @click.stop="down">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.82 13.83"><path d="M0,1.42L1.42,0l10.99,10.99L23.4,0l1.42,1.42-12.41,12.41L0,1.42Z"/></svg>
        </div>
    </template>
    <div class="project-gallery" :class="{ hidden: hidden }">
        <swiper :options="swiperOption" ref="swiper" @slideChange="slideChanged">
            <swiper-slide v-for="(file, index) in data.files" :key="index">
                <div class="img-container" v-if="file.extension == 'jpg'">
                    <img
                        class="swiper-lazy"
                        :data-src="file.url"
                        :data-srcset="file.srcset"
                        :alt="data.title"
                    />
                </div>
                <video-player v-if="['mov', 'mp4'].includes(file.extension)" :file="file.url" :ext="file.extension" />
                <pdf-viewer v-if="file.extension == 'pdf'" :file="file.url" />
                <div class="caption" v-if="!cursorHide">
                    <span class="num">{{ data.num }}.{{ index + 1 }}</span>&nbsp;&nbsp;&nbsp;<span v-if="file.gcaption">{{ file.gcaption }}</span>
                </div>
            </swiper-slide>
        </swiper>
        <transition name="fade">
            <div v-if="title" class="project-title">
                {{ data.title }}<br>
                {{ data.description }}
            </div>
        </transition>
    </div>
    <div class="project-info" v-if="isSupplementary">
        <div v-if="data.audio && data.audio.length > 0" class="project-audio">
            <audio-player :file="data.audio[0].url" :ext="data.audio[0].extension" />
        </div>
        <div class="project-info-text" v-html="data.text"></div>
        <div class="project-press" v-if="press && press.length > 0">
            <div class="press-item">
                <div class="press-title">Related Press</div>
                <div v-for="key in press" :key="key.year" class="press-cv">
                    <div class="press-year" @click="pressYear[key.year] = true">{{ key.year }}</div>
                    <div class="press-entries">
                        <div v-for="(press, index) in key.entries" :key="'p-' + index" class="press-count">
                            <a v-if="press.type === 'link'" :href="press.link" target="_blank">{{ press.title }}</a>
                            <a v-if="press.type === 'file'" :href="press.file" target="_blank">{{ press.title }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="gallery" v-else>
    <div class="loader"><div class="loading"></div></div>
  </div>
</template>

<script>
const VideoPlayer = () => import("./../components/VideoPlayer.vue");
const AudioPlayer = () => import("./../components/AudioPlayer.vue");
const PdfViewer = () => import("./../components/PdfViewer.vue");
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { groupBy, orderBy, map } from "lodash";
import axios from "axios";
export default {
    name: "Project",
    components: {
        swiper,
        swiperSlide,
        VideoPlayer,
        AudioPlayer,
        PdfViewer,
    },
    data() {
        return {
            title: false,
            cursorHide: false,
            cursorTimeout: null,
            cursorZone: false,
            slideIndex: 0,
            loading: true,
            text: false,
            hidden: true,
            data: null,
            swiperOption: {
                threshold: 20,
                effect: 'fade',
                speed: 500,
                allowTouchMove: true,
                breakpoints: {
                    768: {
                        allowTouchMove: true,
                        // effect: '',
                    },
                },
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 2,
                    loadOnTransitionStart: true,
                },
            },
        };
    },
    mounted() {
        this.loadContent(this.$route.params.id);
        this.inactiveCursor();
        window.addEventListener('mousemove', this.inactiveCursor);
        window.addEventListener('click', this.inactiveCursor);

    },
    beforeDestroy() {
        window.removeEventListener('mousemove', this.inactiveCursor);
        window.removeEventListener('click', this.inactiveCursor);
    },
    updated() {
        this.$nextTick(() => {
            this.swiper.destroy = () => {};
        });
    },
    computed: {
        slides() {
            return this.data.files.length;
        },
        swiper() {
            return this.$refs.swiper.swiper;
        },
        press() {
            let group = groupBy(this.data.press, (project) => project.year);
            let mapYears = map(group, function (value, key) {
                return {
                    year: key,
                    open: false,
                    entries: value,
                };
            });
            let sorted = orderBy(mapYears, ["year"], ["desc"]);
            return sorted;
        },
        isSupplementary() {
            return this.data.text.length > 0 || this.data.audio.length > 0 || this.press.length > 0;
        },
        previousProject() {
            return this.slideIndex == 0 && this.data.prev && this.data.prev.uid !== this.data.uid;
        },
        nextProject() {
            return this.slideIndex == this.slides - 1 && this.data.next && this.data.next.uid !== this.data.uid;
        }

    },
    methods: {
        goToSlide() {
            return new Promise((resolve) => {
                if (this.$route.hash == "#last") {
                    this.slideIndex = this.slides - 1;
                } else {
                    this.slideIndex = parseInt(this.$route.hash.substring(1) - 1);
                }
                setTimeout(() => {
                    this.swiper.slideTo(this.slideIndex, 0);
                    this.hidden = false;
                    this.inactiveCursor();
                    resolve();
                }, 100);
            });
        },
        slideChanged() {
            this.$root.$emit("stop-playing");
            let slide = this.data.files[this.slideIndex];
            this.cursorZone = (slide.extension == 'mp4') ? true : false;
            this.inactiveCursor();
        },
        closeGallery() {
            this.$root.$emit("stop-playing");
            this.$emit("gallery", false);
        },
        changeSlide(event) {
            if(event.clientX < window.innerWidth / 2) {
                this.prev();
            } else {
                this.next();
            }
            this.inactiveCursor();
        },
        next() {
            this.slideIndex++;
            this.swiper.slideNext();
            this.$router.push({ hash: "#" + parseInt(this.slideIndex + 1) });
        },
        prev() {
            this.slideIndex--;
            this.swiper.slidePrev();
            this.$router.push({ hash: "#" + parseInt(this.slideIndex + 1) });
        },
        down() {
            this.$el.scroll({
                top: window.innerHeight,
                behavior: 'smooth'
            })
        },
        loadContent(uid) {
            this.loading = true;
            axios.post("/api/query", {
                query: `page('projects').children.find('${uid}')`,
                select: {
                title: true,
                uid: true,
                num: true,
                description: "page.description_md",
                year: true,
                hidetitle: true,
                prev: "page.prev",
                next: "page.next",
                text: "page.text_md.kirbytext",
                press: {
                    query: "page.press.toStructure()",
                    select: {
                        year: "structureItem.year",
                        type: "structureItem.type",
                        title: "structureItem.title",
                        link: "structureItem.link",
                        file: {
                            query: "structureItem.file.toFile()",
                            select: {
                                url: true,
                            },
                        },
                    }
                },
                files: {
                    query: "page.files.sortBy('sort', 'asc').filterBy('extension', '!=', 'mp3')",
                    select: {
                    url: "file.resize(1800).url",
                    srcset: "file.srcset([600, 1200, 1800])",
                    thumb: "file.resize(400).url",
                    index: "file.num",
                    caption: true,
                    gcaption: true,
                    id: true,
                    extension: true,
                    },
                },
                audio: {
                    query: "page.audio.sortBy('sort', 'asc')",
                    select: {
                    url: true,
                    extension: true,
                    },
                },
                },
            },
            {
                auth: {
                username: process.env.VUE_APP_USERNAME,
                password: process.env.VUE_APP_PASSWORD,
                },
            }
            )
            .then((response) => {
                this.data = response.data.result;
                this.goToSlide();
                this.showTitle();
                this.loading = false;
            });
        },
        showTitle() {
            this.title = true;
            setTimeout(() => {
                this.title = false;
            }, 2000);
        },
        inactiveCursor() {
            clearTimeout(this.cursorTimeout);
            if(window.innerWidth > 768) {
                this.cursorHide = false;
                this.cursorTimeout = setTimeout(() => {
                    this.cursorHide = true;
                }, 2000);
            } else {
                this.cursorHide = false;
            }
        }
    },
};
</script>

<style lang="scss">
@keyframes ellipsis {
  to {
    width: 0.9em;    
  }
}

.gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  background: #FFF;
  z-index: 105;
  overflow-y:auto;
  overflow-x:hidden;
  scroll-snap-type: y mandatory;
  transition: transform 0.25s;
  .loader {
    padding:var(--lh);
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
    .loading:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
        animation: ellipsis steps(4,end) 900ms infinite;
        content: "\2026"; /* ascii code for the ellipsis character */
        width: 0px;
    }
  }
  .project-gallery {
    scroll-snap-align: start;
    height:100%;
    position: relative;
    &.hidden {
        opacity: 0;
    }
    .project-caption {
        position: absolute;
        bottom: var(--lh);
        left: calc(6ch + var(--lh));
        z-index:99;
    }
    .project-title {
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        text-align: center;
    }
  }
  .project-info {
    scroll-snap-align: start;
    min-height:100%;
    padding:var(--lh);
    .project-info-title, .project-info-text, .project-press {
        max-width: 1024px;
        margin: calc(2 * var(--lh)) auto;
        p:not(:last-of-type) {
            margin-bottom: var(--lh);
        }
    }
    .project-press {
        margin-top: calc(2 * var(--lh));
        .press-cv {
            display: flex;
            .press-year {
                width: calc(4 * var(--lh));
                display: inline-block;
            }
        }
    }
  }
  .close,
  .next,
  .prev,.down {
    color: #000;
    position: absolute;
    cursor: pointer;
    user-select: none;
    transition: opacity 0.5s, color 0.5s;
    z-index: 997;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
  }
  .down {
    bottom:0;
    right:0;
    padding: var(--lh);
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
    svg {
        width:0.75rem;
        transform: translateY(-50%);
    }
  }
  .next {
    top:0;
    right:0;
    width:75%;
    height:100%;
    opacity: 0.5;
    &:hover {
        opacity: 1;
    }
    @media screen and (max-width: 768px) {
        width:10%;
        display: none;
    }
    &.limited {
        width:10%;
    }
    svg {
        position: absolute;
        top: 50%;
        right: calc(1 * var(--lh));
        transform: translateY(-50%);
        pointer-events: auto;
        height:0.75rem;
        @media screen and (max-width: 768px) {
            // width:0.75rem;
            right: calc(0.5 * var(--lh));
        }
    }

  }
  .prev {
    top:0;
    left:0;
    width:25%;
    height:100%;
    &.limited {
        width:10%;
    }
    @media screen and (max-width: 768px) {
        width:10%;
        display:none;
    }
    svg {
        position: absolute;
        top: 50%;
        left: calc(1 * var(--lh));
        transform: translateY(-50%);
        pointer-events: auto;
        height:0.75rem;
        @media screen and (max-width: 768px) {
            left: calc(0.5 * var(--lh));
        }
    }
  }
  .close {
    z-index:998;
    top: 0;
    right:0;
    padding: calc(1 * var(--lh));
    svg {
        // @media screen and (max-width: 768px) {
            width:0.75rem;
        // }
    }
  }
//   @media only screen and (max-width: 1024px) {
//     .next {
//       top: calc(2 * var(--lh));
//       right: calc(2 * var(--lh));
//     }
//     .prev {
//       top: calc(2 * var(--lh));
//       left: auto;
//       right: calc(5 * var(--lh));
//     }
//   }
.swiper-container {
    height:100%;
}
  .swiper-wrapper {
    height: 100%;
    .swiper-slide {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background:white;
      color: #000;
      .caption {
        position: absolute;
        text-align:left; 
        color: #000;
        bottom: calc(1 * var(--lh));
        left: calc(1 * var(--lh));
      }
      .img-container {
        padding: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        text-align: center;
        display:flex;
        flex-direction: row;
        justify-self: center;
        align-items:center;
        // @media only screen and (max-width: 768px) {
        //   padding: calc(6 * var(--lh)) calc(2 * var(--lh));
        // }
      }
      img {
        box-sizing: border-box;
        width:100%;
        height:100%;
        border: 0;
        outline: none;
        opacity: 0;
        object-fit: contain;
        transition: opacity 0.25s;
        &.swiper-lazy-loaded {
          opacity: 1;
        }
      }
      .pdf {
        padding: calc(2 * var(--lh)) calc(4 * var(--lh));
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        @media only screen and (max-width: 768px) and (orientation: portrait) {
          padding: calc(6 * var(--lh)) calc(2 * var(--lh));
        }
        object {
          width: 100%;
          height: 100%;
          overflow: scroll;
          @media only screen and (max-width: 768px) {
            object-fit: contain;
            background: #000;
          }
        }
      }
      .text {
        padding: calc(2 * var(--lh)) calc(4 * var(--lh));
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        -ms-overflow-style: none;
        // IE 10+
        scrollbar-width: none;
        // Firefox
        &::-webkit-scrollbar {
          display: none;
          // Safari and Chrome
        }
        @media only screen and (max-width: 768px) {
          padding: calc(6 * var(--lh)) calc(2 * var(--lh));
        }
        &:before {
          content: "";
          position: absolute;
          top: calc(6 * var(--lh));
          right: calc(8 * var(--lh));
          bottom: calc(6 * var(--lh));
          left: calc(8 * var(--lh));
          @media only screen and (max-width: 768px) {
            right: calc(2 * var(--lh));
            left: calc(2 * var(--lh));
          }
          box-sizing: border-box;
          pointer-events: none;
          box-shadow: inset 0 0 15px 15px #FFF;
          z-index: 20;
        }
        .text-inner {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          overflow: scroll;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: none;
          // IE 10+
          scrollbar-width: none;
          // Firefox
          &::-webkit-scrollbar {
            display: none;
            // Safari and Chrome
          }
          padding: calc(4 * var(--lh));
          position: relative;
          @media only screen and (max-width: 768px) {
            padding: calc(2 * var(--lh));
          }
          p {
            margin-bottom: var(--lh);
          }
        }
      }
    }
  }
}
</style>
